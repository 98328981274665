<template>
    <div class="flex justify-center mb-4 ins_box">
        <div class="px-2 ins_inner">
            <div class="text-left text-gray-400 duration-500 title pc_show">真伪查验</div>
            <div class="flex items-center justify-center sm:mt-3 from_box">
                <div>
                    <el-form ref="form" :model="form" label-width="100px" label-position="center">
                        <el-form-item label="序列号" class="flex justify-start">
                            <el-input
                                v-model="form.serial"
                                placeholder="请填写序列号"
                                class="duration-500 form_input"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="." class="flex justify-start btn_item">
                            <el-button type="primary" @click="onSubmit" class="duration-500 btn">确定</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="info">
                <div
                    class="text-left text-gray-400 duration-500"
                >1、可在笔记本机身背面（或包装箱标签）查看序列号（一般会写着S/N或Serial Number，有的没有写，而是一串代码）</div>
                <div
                    class="mt-1 text-left text-gray-400 duration-500"
                >2、仅支持2022年4月1日后生产的机器查询，非该时间范围的可联系客服查询</div>
            </div>
            <div class="pt-8" v-if="model">
                <div class>查询结果</div>
                <div class="flex justify-center py-2">
                    <img :src="logoPic.head" alt />
                </div>
                <div class="mt-2 font-bold text-center">{{ model }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            form: {
                serial: "",
                smsCode: '',
            },
            model: ""
        };
    },
    computed: {
        ...mapState(['logoPic'])
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        onSubmit() {
            if (this.isNull(this.form.serial)) {
                // this.$message.error("IMEI或MEID的值不能为空");
                this.$EventBus.$emit("toast", { type: 'error', message: "序列号不能为空" })
                return
            }
            // if (this.isNull(this.form.smsCode)) {
            //     this.$message.error("验证码不能为空");
            //     return
            // }
            this.$request({
                method: "get",
                url: this.$requestPath.authenticity,
                params: {
                    coding: this.form.serial
                }
            }).then(res => {
                console.log("真伪校验", res)
                if (res.code == 0 && res.data) {
                    res.data ? this.$EventBus.$emit("toast", { type: 'success', message: "设备查询成功" }) : this.$EventBus.$emit("toast", { type: 'error', message: "无法获取该设备信息" })
                    this.model = res.data
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("真伪校验", error)
            })
        }
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}
.ins_inner {
    max-width: 49.375rem;
    min-height: var(--min-height);
}

@media screen and (max-width: 640px) {
    .form_input {
        width: 13.75rem;
    }

    .btn {
        width: 6.25rem;
    }
    .from_box {
        margin-top: 0.9375rem;
    }
    .info {
        font-size: 0.75rem;
    }
}
@media screen and (max-width: 900px) {
    .pc_show {
        display: none;
    }
    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .form_input {
        width: 18.75rem;
    }
    .btn {
        width: 12.5rem;
    }
}
@media screen and (min-width: 900px) {
    .pc_show {
        display: block;
    }
    .title {
        font-size: 1.875rem;
    }
}
</style>
<style>
.ins_box .el-form-item__content {
    margin-left: 0.625rem !important;
}
</style>